@import "styles/fonts.scss";
@import "styles/variables";
@import "styles/tnlm-font";
@import "styles/custom.scss";
@import "styles/animate.min.scss";

body {
  margin: 0;
  font-family: $font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $grey-light;
}

code {
  font-family: $font-regular;
}

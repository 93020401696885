@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');

@font-face{
    font-family: 'Proxima Nova Regular';
    src : url("./fonts/proxima_nova_regular.otf") format("opentype");
    src : url("./fonts/proxima_nova_regular.ttf") format("truetype");
    src : url("./fonts/proxima_nova_regular.woff") format("woff");
    src : url("./fonts/proxima_nova_regular.woff2") format("woff2");
}

@font-face{
    font-family: 'Proxima Nova Semibold';
    src : url("./fonts/proxima_nova_semibold.otf") format("opentype");
    src : url("./fonts/proxima_nova_semibold.ttf") format("truetype");
    src : url("./fonts/proxima_nova_semibold.woff") format("woff");
    src : url("./fonts/proxima_nova_semibold.woff2") format("woff2");
}

@font-face{
    font-family: 'Proxima Nova Bold';
    src : url("./fonts/proxima_nova_bold.otf") format("opentype");
    src : url("./fonts/proxima_nova_bold.ttf") format("truetype");
    src : url("./fonts/proxima_nova_bold.woff") format("woff");
    src : url("./fonts/proxima_nova_bold.woff2") format("woff2");
}

@font-face{
    font-family: 'Proxima Nova Light';
    src : url("./fonts/proxima_nova_light.otf") format("opentype");
    src : url("./fonts/proxima_nova_light.ttf") format("truetype");
    src : url("./fonts/proxima_nova_light.woff") format("woff");
    src : url("./fonts/proxima_nova_light.woff2") format("woff2");
}
// Colors
$red-bci: #ff0000;
$red-light: #ffe8e8;

$grey-light: #f7f7f7;
$grey-regular: #e0e0e0;
$grey-cool-bci: #808080;
$grey-text: #6a6a73;
$grey-dark: #6a6a73;
$grey-table-stripped: "#F7F8FA";
$grey-table-border: "#CED3D8";

$black: #454550;

$blue: #215d95;
$blue-light: #ecf7ff;

$tortoise: #03989e;
$tortoise-light: #d8f4f5;

$white: #fff;

// New Colors
$trolley-gray: #808080;
$gray-94: #f0f0f0;
$new-gray: #cac8c8;
$magenta: #ff7676;
$bci-blue: #0039ff;

$green: #0da738;

// Fonts
$font-regular: "Roboto", sans-serif;
$font-prox-semibold: "Proxima Nova Semibold", sans-serif;
$font-prox-bold: "Proxima Nova Bold", sans-serif;
$font-prox-light: "Proxima Nova Light", sans-serif;
$font-prox-reg: "Proxima Nova Regular", sans-serif;

// width
$width-sidebar: 60px;

// box-shadow-regular
$box-shadow-regular: 2px 2px 10px rgba(0, 0, 0, 0.05) !important;

// border-radius-regular
$border-radius-regular: 8px;

.search-result {
  .item-list-card {
    position: relative;
    margin-bottom: 16px;
    background-color: rgb(255, 255, 255);
    // border: 1px solid #E0E0E0;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 14.25px 15.25px;
    overflow: hidden;
    border-radius: 8px;
    .item-badge-archived {
      display: none;
    }
    &.clickable {
      cursor: pointer;
    }
    &.selected {
      &:after {
        content: "";
        width: 4px;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background: #ff0000;
      }
    }
    &.archived {
      &:after {
        width: 0px !important;
      }
      .item-list-card-title,
      .item-list-card-subtitle1,
      .item-list-card-subtitle2,
      svg,
      .MuiTypography-body1,
      a {
        color: #cac8c8 !important;
      }
      .item-list-card-checkbox,
      .item-link-detail {
        display: none !important;
      }
      .item-badge-archived {
        display: inline-block !important;
      }
    }
    .item-list-card-checkbox {
      .custom-checkbox-container {
        padding: 0;
        .checkbox-checkmark {
          margin-right: 5px;
        }
      }
    }
    .item-list-card-list {
      margin-top: 12.25px;
    }
    .item-list-card-header {
      display: flex;
      margin-bottom: 8px;
      .item-list-card-texts {
        flex: 1 1 0%;
        align-items: start;
        margin-left: 5px;
        margin-right: 5px;
      }
      .item-list-card-title {
        display: flex;
        align-items: flex-start;
        font-weight: bold !important;
        margin-bottom: 8px;
      }
      .item-list-card-subtitle1 {
        margin-bottom: 8px;
      }
    }
    .item-list-card-flags {
      display: flex;
    }
  }
  .search-detail-project {
    .project-detail-contact-accordion {
      display: flex;
      flex-direction: column;
      .MuiAccordionSummary-root {
        margin: 0 0 -1px 0;
        padding: 0 23px 0 10px;
      }
      .MuiAccordionDetails-root {
        background-color: #fcfcfc;
        flex-direction: column;
      }
      .project-detail-contact-role-card,
      .project-detail-contact {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
        &:first-child {
          margin-top: 8px;
        }
        .head-title {
          padding: 14px 15px;
          border-bottom: 1px solid #e0e0e0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $grey-light;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          .watclisted {
            display: flex;
            border: 1px solid #e0e0e0;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            color: #6a6a73;
            cursor: pointer;
            border-radius: 4px;
            padding: 7px;
            background-color: $white;
            .tnlm-font {
              margin-right: 5px;
              fill: #6a6a73;
            }
          }
        }
        .content-wrap {
          width: 100%;
          .content {
            // background-color: #fafafa;
            padding: 14px 15px;
            display: flex;
            border-radius: 0 0 8px 8px;
            width: 100%;
            &:last-child {
              border: none;
            }
            .buttons {
              display: flex;
              .button-item {
                padding: 0 2.5px;
              }
            }
            .information {
              flex: 1;
              .info-contacts {
                display: flex;
                .info {
                  flex: 1 0 50%;
                  max-width: 50%;
                  margin-right: 20px;
                  &:last-child {
                    margin-bottom: 10px;
                  }
                }
              }
              .info {
                display: flex;
                margin-bottom: 10px;
                .tnlm-font {
                  margin-right: 10px;
                  color: #cac8c8;
                }
              }
            }
          }
          &.role-card {
            background-color: #ffffff;
            .content {
              padding: 0;
              border: none;
            }
          }
        }
      }
      .project-detail-contact-role-card {
        padding: 20px;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.11);
        border: 1px solid #e8e8e8;
      }
    }
  }
}
